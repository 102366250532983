import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import i18n from '@/i18n'
import { userService } from "../services/user.service"


const router = createRouter({
  history: createWebHistory(), // createWebHashHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();

  const ret = await userService.loadProfile();
  if (ret)
    next();
  else {
    if (routeTo.fullPath == '/')
      next({ name: 'login' })
    else
      next({ name: 'login', query: { url: routeTo.fullPath } });
  }
});

router.beforeEach((routeTo, routeFrom, next) => {

  const publicPages = ['/login', '/forgot-password', '/logout', '/auth/lockscreen'];
  let authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem('user');
  const lockscreen = localStorage.getItem('lockscreen');

  // double check authPage
  if (authpage && routeTo.path.indexOf('/v/') >= 0) {
    authpage = false
  }

  if (authpage && !loggeduser) {
    return next('/login');
  }
  else if (authpage && lockscreen == 1) {
    return next({ name: 'lockscreen', query: { url: routeTo.fullPath } });
  }

  next();
});



router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = (routeTo.meta.tkey ? i18n.global.t(routeTo.meta.tkey) : routeTo.meta.title) + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;
