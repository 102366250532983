import { userService } from "../services/user.service";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/front/home/upload.vue"),
  },
  {
    path: '/v/:uploadId',
    name: 'shareLink',
    meta: {
      title: "View upload files", authRequired: false,
    },
    component: () => import('../views/front/home/share.vue')
  },
  {
    path: '/view/:id',
    name: 'viewUpload',
    meta: {
      title: "View upload files", authRequired: true,
    },
    component: () => import('../views/front/home/view.vue')
  },
  {
    path: '/my-upload',
    name: 'myUpload',
    meta: {
      title: "My upload", authRequired: true,
    },
    component: () => import('../views/front/my-upload/index.vue')
  },
  {
    path: '/change-password',
    name: 'changePwd',
    meta: {
      title: "Change password", authRequired: true,
    },
    component: () => import('../views/account/change-password.vue')
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    meta: {
      title: "Edit profile", authRequired: true,
    },
    component: () => import('../views/account/edit-profile.vue')
  },
  {
    path: '/email-signature',
    name: 'emailSignature',
    meta: {
      title: "Email signature", authRequired: true,
    },
    component: () => import('../views/account/email-signature.vue')
  },


  {
    path: "/admin",
    name: "adminDefault",
    redirect: '/admin/accounts',
    component: () => import("../views/admin/account/index.vue"),
  },
  {
    path: "/settings/general",
    name: "generalSettings",
    meta: { title: "General settings", authRequired: true },
    component: () => import("../views/settings/general-settings.vue"),
  },
  {
    path: "/settings/email-template",
    name: "emailTemplate",
    meta: { title: "Email template", authRequired: true },
    component: () => import("../views/settings/email-template/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (userService.getJwt()) {
          // Redirect to the home page instead
          next({ path: "admin/uploads" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "adminForgotPassword",
    component: () => import("../views/account/forgot-password.vue"),
    meta: { title: "Forgot Password", authRequired: false }
  },
  {
    path: "/reset-password/:token",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: { title: "Reset Password", authRequired: false }
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout")
  },
  {
    path: '/auth/lockscreen',
    name: 'lockscreen',
    meta: {
      title: "Lock Screen", authRequired: false,
    },
    component: () => import('../views/auth/lockscreen')
  },
  {
    path: '/error/500',
    name: '500',
    meta: {
      title: "Internal Error", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/error/404',
    name: '404-not-found',
    meta: {
      title: "Not Found", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/offline',
    name: 'offline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/offline')
  },
  {
    path: '/auth/success-msg',
    name: 'success-msg',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg')
  },
  {
    path: '/admin/uploads',
    name: 'adminUpload',
    meta: {
      title: "Upload files", authRequired: true,
    },
    component: () => import('../views/admin/upload/index.vue')
  },
  {
    path: '/admin/uploads-expired',
    name: 'adminUploadExpired',
    meta: {
      title: "Upload expired files", authRequired: true,
    },
    component: () => import('../views/admin/upload/index.vue')
  },
  {
    path: '/admin/accounts',
    name: 'adminAccounts',
    meta: {
      title: "Accounts", authRequired: true,
    },
    component: () => import('../views/admin/account/index.vue')
  },
  {
    path: '/admin/licenses',
    name: 'Licenses',
    meta: {
      title: "Licenses", authRequired: true,
    },
    component: () => import('../views/admin/license/index.vue')
  },
  {
    path: '/admin/account/:id/license',
    name: 'accountLicense',
    meta: {
      title: "Account license", authRequired: true,
    },
    component: () => import('../views/admin/license/index.vue')
  },
  {
    path: '/admin/account/:id/upload',
    name: 'accountUpload',
    meta: {
      title: "Account upload", authRequired: true,
    },
    component: () => import('../views/admin/upload/index.vue')
  }
];