import axios from 'axios';
import { UrlHelper } from "../helpers/url.helper"

export const userService = {
    login,
    logout,
    loadProfile,
    getUserinfo,
    isUser,

    setJwt,
    getJwt,

    forgotPassword,
    changePassword
};

async function login(username, password) {
    try {
        const url = UrlHelper.resolveUrl('token');
        const data = `userName=${username}&password=${encodeURIComponent(password)}&grant_type=password&client_id=web`;

        const result = await axios.post(url, data);

        if (result.data.access_token)
            setJwt(result.data.access_token);

        return result.data;
    }
    catch (err) {
        return err;
    }
}

function logout() {
    localStorage.clear()
}

async function loadProfile() {
    let url = UrlHelper.resolveApiUrl('auth/getCurrentUser');

    try {
        let ret = await axios.get(url);
        if (ret.data.data) {
            localStorage.setItem('userid', ret.data.data.id);
            localStorage.setItem('user', JSON.stringify(ret.data.data));

            return ret.data.data;
        }
    }
    catch (err) {
        return null;
    }

    return null;
}

function getUserinfo() {
    const userData = localStorage.getItem('user') || localStorage.getItem('userdata')
    if (userData)
        return JSON.parse(userData);

    return null;
}

function isUser() {
    const user = getUserinfo() || {};

    if (user.userName.indexOf('@') >= 0 && user.accountId > 0)
        return true;

    return false;
}

async function forgotPassword(email) {

    try {
        const url = UrlHelper.resolveApiUrl(`auth/forgotPassword?email=${email}`);
        const result = await axios.post(url);
        return result.data;
    }
    catch (err) {
        return { error: true, message: err }
    }
}

async function changePassword(model) {

    try {
        const url = UrlHelper.resolveApiUrl(`auth/ChangePwd`);
        const result = await axios.post(url, { data: model });
        return result.data.data;
    }
    catch (err) {
        return false;
    }
}

function setJwt(jwt) {
    localStorage.setItem('jwt', jwt)
}

function getJwt() {
    return localStorage.getItem('jwt')
}